/* eslint-disable no-unused-vars */
import React, { createRef, useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';

import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from 'reactstrap';

import classnames from 'classnames';
import NotificationAlert from 'react-notification-alert';

import { login, isLoggedIn } from 'services/authService';
import AuthHeader from 'components/Headers/AuthHeader.js';
import AuthFooter from 'components/Footers/AuthFooter';
import ERPFuzeLogo from 'assets/img/brand/ERPFuze_logo.svg';
import MSLogo from 'assets/img/brand/ms-svg.svg';
import { LoginAction, options } from './helper';
import Swal from 'sweetalert2';
// window.Swal = Swal;
import { erpfapp, erpfapi } from '../../variables/url';





const imageStyle = { display: 'block', objectFit: 'center center', width: '200px', height: 'auto' };

const LoginPage = () => {

  //---------- Variables ----------//
  const notificationAlertRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false); 
  const [focusedPassword, setFocusedPassword] = useState(false); 

  //Will hold the value of the username and password field to be sent to signin()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [type, setType] = useState('password'); // Used within the password input field to toggle between displaying the password as plain text or as obscured characters (like dots). 
  // --------------------//




  useEffect(() => { // check everytime if isloggedin () is valid
    if (isLoggedIn()) {
      navigate('/dashboard/home');
    }
  });

  const theURL = typeof window !== 'undefined' ? window.location.href : '';

  const user= ({
    token:  theURL.split('param2=').pop().split('&')[0],
    userid:  theURL.split('param3=').pop().split('&')[0],
    username:  theURL.split('param4=').pop().split('&')[0],
    role:  theURL.split('param1=').pop().split('&')[0],
  });



  if (theURL.includes('param1=')){
    login(user);
    localStorage.setItem('loginWith', JSON.stringify({0: '365', 1: user.username}));
  } else if(theURL.includes('?param=unauthorized'))
    {
    Swal.fire({ title: 'Unauthorized', text: 'Please contact the site administrator', icon: 'info', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Okay' })
    .then((result)=>{
      if(result.isConfirmed){
        // window.location.href='/';
        navigate(`https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${erpfapp}`);
      }else{
        navigate('/');
      }
    });
  }

  const signin = async (e) => { // Called after pressing submit on the login form
    e.preventDefault(); // Prevents the default behavior of the function which is to submit the form &reload the page
    setLoading(true);

    try {
      const { data } = await LoginAction({ username, password }); // From helper.js | This connects to the backend at port 8001
      login(data);

      window.location.href = '/dashboard/home';

    } catch({ response, message }) {
      const data = response;
      console.log('Catch: ', data);
      console.log('Catch: ', message);
      notificationAlertRef.current.notificationAlert(
        options('danger', 'Error', data?.data?.message ? data.data.message : message)
      );

      setLoading(false);
    }
  };





  return <>
    <div className="rna-wrapper">
      <NotificationAlert ref={notificationAlertRef} />
    </div>

    <AuthHeader/>

    <div className="login-form">
      <Row className="justify-content-center">
        <Col lg="12">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 pt-3">

              <div className="d-flex align-items-center justify-content-center my-4 mb-4" style={{maxHeight: '80px'}}>
                <img alt="..." src={ERPFuzeLogo} style={imageStyle} />
              </div>

              <div className="text-center my-2 mb-2">
                <small>Sign in with credentials</small>
              </div>

              <Form role="form" onSubmit={ signin } >
                <FormGroup className={classnames('mb-3', { focused: focusedEmail })}>
                  <InputGroup className="input-group-merge input-group-alternative">
                    
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{height: '100%'}}>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input name="username" placeholder="Username" type="text" data-cy="username" required onFocus={() => setFocusedEmail(true)} onBlur={() => setFocusedEmail(false)} onChange={e => setUsername(e.target.value)} />
                  
                  </InputGroup>
                </FormGroup>

                <FormGroup  className={classnames({ focused: focusedPassword })}>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{height: '100%'}}>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password" placeholder="Password" type={type} data-cy="password" required onFocus={() => setFocusedPassword(true)} onBlur={() => setFocusedPassword(false)} onChange={e => setPassword(e.target.value)}/>
                    <i
                      className="fa fa-eye position-absolute top-3 right-2 cursor-pointer"
                      onMouseDown={() => setType('text')}
                      onMouseUp={() => setType('password')}
                    ></i>
                  </InputGroup>
                </FormGroup>

                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                  <label className="custom-control-label" htmlFor=" customCheckLogin">
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>

                <div className="text-center">
                  <Button className="my-4" color="info" type="submit" disabled={ isLoading } data-cy="login-button">
                    { isLoading ? 'Loading...' : 'Sign in' }
                  </Button>
                </div>

                <div className="text-center" style={{border:'1px #8c8c8c'}}>
                  <a href={`${erpfapi}/login365`}>
                    <Button className="m-auto" type="button" outline color="default" data-cy="Microsoft-button">
                      <img alt="..." src={MSLogo} style={{height: 23}} />
                      &nbsp;&nbsp;Sign in with Microsoft </Button>
                  </a>
                </div>

              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AuthFooter />
    </div>
  </>;
};

export default LoginPage;