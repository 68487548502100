
import React from 'react';
import Axios from 'axios';
import { erpfapi } from 'variables/url';

export const LoginAction = ({ username, password }) => { // This connects to the backend at port 8001 in development, and returns data to be used by the 'login' function on ./authservice.
  console.log('The backend site is: ', erpfapi);
  return Axios({
    method: 'post',
    url: erpfapi + '/token',
    data: { username, password }
  });
};


export const options = (type, title, message) => {
  return {
    place: 'tc',
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          { title }
        </span>
        <span data-notify="message">
          { message }
        </span>
      </div>
    ),
    type: type,
    autoDismiss: 5
  };
};