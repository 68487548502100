import React from 'react';
import { Router } from '@reach/router';

import Login from 'views/auth/Login.js';
import AuthLayout from 'layouts/Auth.js';

const AuthClient = () => (
  <AuthLayout>
    <Router basepath="/auth">
      {/*<Pricing path="/pricing" />*/}
      {/*<Register path="/register" />)*/}
      {/*<Lock path="/lock" />*/}
      {<Login path="/login" default/>}
    </Router>
  </AuthLayout>
);

export default AuthClient;
